export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardando..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hoy a ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ayer a ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes permisos para hablar en este canal."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin conección"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar un mensaje"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Escribe un mensaje!"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevos mensajes"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimir"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir a"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver perfil"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar roles"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echar"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banear"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citar"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como leído"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar servidor"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir del servidor"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silenciar canal"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar sonido del canal"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar emojis"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de inicio"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Activity"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar Bots"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de cambios"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracteristicas experimentales"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-conectar Google Drive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar Google Drive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones extra"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta información la puede ver todo el mundo!"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuracion de notificaciones."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido en notificaciones"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las notificaciones harán un sonido para conseguir tu atención"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notification"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones visibles en tu pantalla, solo se mostraran cuando Nertivia este oculto"])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Sube emojis gratuitamente! (Su peso no debe superar los 3MB)."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensiones soportadas: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir emojis"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de inicio"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas el cliente de escritorio para modificar estas opciones."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir Nertivia al inicio"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte programas o juegos que estés usando añadiendo apps abajo. Esto va a remplazar tu estatus personalizable cuando un programa es abierto y se revertirá cuando el programa este cerrado."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usar esta característica debes descargar la app para escritorio de Nertivia."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Bots al que le daremos acceso a nuestra API de Bots"])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade comandos para hacer que los usuarios sepan facilmente que comandos tiene tu Bot."])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir comando"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar comandos"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear link de invitación"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar link de invitación"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar Token"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Token"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar Token"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer Token"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás apunto de eliminar tu cuenta"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo que se va a eliminar"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo que NO se va a eliminar"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus mensajes enviados en servidores publicos"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir/Eliminar todos tus servidores"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar todos tus Bots"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede que despues de cambiar el idioma debas reiniciar Nertivia para que se apliquen los cambios. Puedes contribuir enviando una PR a nuestro repo de GitHub"])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductores"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la apariencia de Nertivia."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colores"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema por defecto"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED oscuro"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas pre-definidos"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS customizable"])},
      "color-scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquema de colores"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilita caracteristicas en proceso o experimentales."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay caracteristicas WIP por ahora."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar TODOS los permisos"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensajes"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensajes en canales"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar roles"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar o borrar roles"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar canales"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar o borrar canales"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echar"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echar a un usuario del servidor"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echa a un usuario e impide que vuelva a entrar"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canales"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitaciones"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad del servidor"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios baneados"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar servidor"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configura los roles de tu servidor."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol por defecto"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol de Bot"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un rol"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este rol no puede ser eliminado ni asignado a otros miembros ya que fue creado para un bot. Remueve el bot para eliminar el rol."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este rol no se puede quitar de usuarios ya que es el rol por defecto aplicado a cada miembro."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color del rol"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del rol"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar rol"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimando rol..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder rol"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuracion general"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestiona tus canales."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar canal"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrando canal..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del canal"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un canal por defecto. Puedes cambiar el canal por defecto en la página general. Los canales por defecto no pueden ser eliminados."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo canal"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensajes"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de Velocidad (segundos)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de texto"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbanea usuarios desde aquí"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbaneando..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbanear"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo los administradores pueden ver todas las invitaciones."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear invitaciones"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación personalizada"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar link personalizado"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear invitación"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creada por"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usos"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar usuario"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe el nombre del servidor para confirmar"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminando..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar notificaciones."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios que hagas solo se aplicarán a ti y no a otros usuarios."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar notificaciones"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silenciar notificaciones"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshabilitar notificaciones"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " está escribiendo..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " y ", _interpolate(_list(1)), " están escribiendo..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " y ", _interpolate(_list(2)), " están escribiendo..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " usuarios están escribiendo..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigos"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciente"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas guardadas"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir amigo"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clickea para agregar un estado personalizado"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Al día!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes directos"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidores"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para añadir a un amigo escribe su nombre de usuario y su etiqueta."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadiendo..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir servidor"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a un servidor con una invitación."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un servidor. Primero elije el nombre."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invisible"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausente"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocupado"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con ganas de jugar"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usuarios (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Desconectados (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigos en común"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insignias"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entró"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendido"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar de amigos"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar solicitud"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar solicitud"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir amigo"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidores en común"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Listo!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticando..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Actualización disponible!"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Copiado!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nos ha abandonado"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fue echado"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha sido baneado"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha unido"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo conectar al servidor."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de Velocidad habilitado"])}
  }
}